.unProtectedRouteLayout{
    width: 100%;
    min-height: 100vh;
    min-height: 100dvh;

    & .loading{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }

    // display: flex;
    // justify-content: center;
    // align-items: center;
}
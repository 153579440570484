@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');

header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3%;
    color: #3e3e42;
    position: fixed;
    top: 0;
    z-index: 3;

    & .logo {
        text-decoration: none;
        font-family: "Kalam", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        font-size: 1.5rem;
        background-color: #CCCBC4;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        padding: 10px 20px;
        color: #3e3e42;
        outline: none;

        &:focus {
            color: #3e3e42;
        }
    }

    & nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        & ul {
            list-style: none;
            padding: 10px;
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
            border-radius: 100px;
            background-color: #CCCBC4;

            & li {

                & .link {
                    text-decoration: none;
                    color: #3e3e42;
                    min-width: 120px;
                    padding: 10px;
                    border-radius: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s linear;

                    &:hover {
                        background-color: white;
                    }
                }

                & .cartBtn {
                    min-width: unset;
                }

                & .link.active {
                    background-color: white;
                }
            }
        }
    }
}
.main {

    & .landing {
        height: 100vh;
        padding: 2%;
        position: relative;
        border-radius: 100px;

        & .background {
            width: 100%;
            height: 100%;
            max-height: 100vh;
            border-radius: 46px;
            object-fit: cover;
            object-position: 50% 50%;
        }

        & .content {
            position: absolute;
            bottom: 50px;
            left: 0;
            width: 100%;
            padding: 30px 3%;
            display: flex;
            align-items: flex-end;
            flex-direction: row;
            justify-content: space-between;

            & .textContent {
                color: black;

                & h1 {
                    font-size: 6rem;
                    font-weight: 500;
                }

                & p {
                    font-size: 2rem;
                }
            }

            & .link {
                text-decoration: none;
                height: fit-content;
                min-width: 300px;
                background-color: #000000;
                color: white;
                border-radius: 100px;
                padding: 20px;
                border: none;
                outline: transparent;
                font-weight: 100;
                cursor: pointer;
                transition: all 0.2s linear;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    background-color: hsl(240, 4%, 45%);
                }
            }
        }
    }
}
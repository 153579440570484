.cart {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2%;
    padding-bottom: 3%;
    position: relative;
    border-radius: 100px;

    & .background {
        width: 100%;
        min-height: 95vh;
        border-radius: 46px;
        background-color: #4b4843;
        padding: 200px 3% 100px 3%;
        display: flex;
        justify-content: center;
        gap: 100px;
        color: whitesmoke;

        & h2 {
            font-weight: 100;
        }

        & h3 {
            font-weight: 100;
        }

        & h4 {
            font-weight: 100;
        }

        & .cartProduct {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 40px;

            & .products {
                display: flex;
                flex-direction: column;
                gap: 30px;

                & .product {
                    display: flex;
                    gap: 20px;

                    & img {
                        width: 100px;
                        height: 100px;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 10px;
                    }

                    & .productInfo {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;

                        & .quantity-price {
                            display: flex;
                            justify-content: space-between;
                            gap: 60px;

                            & .quantityInput {
                                border: 1px solid whitesmoke;
                                padding: 5px 10px;
                                border-radius: 100px;
                                height: fit-content;
                                width: 150px;
                                display: flex;
                                align-items: center;

                                & .btn {
                                    cursor: pointer;
                                    background: none;
                                    outline: transparent;
                                    border: none;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: whitesmoke;

                                    &:disabled {
                                        color: rgba(245, 245, 245, 0.274);
                                    }
                                }

                                & input {
                                    border: none;
                                    background: none;
                                    outline: transparent;
                                    color: whitesmoke;
                                    width: 100%;
                                    text-align: center;
                                }

                                input::-webkit-outer-spin-button,
                                input::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }

                                input[type=number] {
                                    -moz-appearance: textfield;
                                }
                            }
                        }

                        & button {
                            background: none;
                            border: none;
                            outline: transparent;
                            color: whitesmoke;
                            height: fit-content;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        & .productPrice {
            width: 20%;
            display: flex;
            flex-direction: column;
            gap: 40px;

            & .price {
                display: flex;
                flex-direction: column;
                gap: 20px;

                & button {
                    background-color: white;
                    border: none;
                    outline: transparent;
                    border-radius: 10px;
                    padding: 10px;
                    cursor: pointer;
                }
            }
        }
    }
}
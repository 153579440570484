.shop {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2%;
    padding-bottom: 3%;
    position: relative;
    border-radius: 100px;

    & .background {
        width: 100%;
        min-height: 100vh;
        border-radius: 46px;
        background-color: #4b4843;
        padding: 200px 3% 100px 3%;

        & h2 {
            color: white;
            font-size: 5rem;
            font-weight: 100;
        }

        & .filter-products {
            display: flex;
            flex-direction: row;
            gap: 50px;

            & aside {
                min-width: 300px;
                width: fit-content;
                height: 500px;
                display: flex;
                flex-direction: column;
                gap: 50px;

                & .section {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    & h3 {
                        color: white;
                    }

                    & label {
                        color: #ffffff;
                        width: fit-content;

                        & span {
                            font-family: "Yanone Kaffeesatz", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                                sans-serif;
                        }
                    }

                    & p {
                        color: white;
                    }

                    & hr {
                        width: 98%;
                        border: none;
                        border-top: 1px solid rgb(114, 114, 114);
                    }
                }

                & .price {

                    & .priceRange {
                        display: flex;
                        flex-direction: column;

                        & .value {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            gap: 10px;
                        }
                    }
                }
            }

            & .products {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                & .product {
                    width: 33.33%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                    padding: 20px 20px;
                    color: white;
                    text-decoration: none;

                    & img {
                        width: 100%;
                        height: 616px;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 40px;
                    }
                }
            }
        }
    }
}
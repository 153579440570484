footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #CCCBC4;
    color: #3e3e42;
    gap: 50px;
    padding-top: 50px;

    & .content {
        display: flex;
        justify-content: center;
        flex-direction: row;
        gap: 70px;
        width: 100%;
        padding: 20px;

        & .section{
            width: 20%;
            display: flex;
            flex-direction: column;
            gap: 30px;

            & h2{
                font-weight: 500;
            }

            & ul{
                list-style: none;
                padding: 0;
                margin: 0;

                & a{
                    text-decoration: none;
                    color: #3e3e42;
                }
            }
        }

        & .newsLetter {

            & form{
                display: flex;
                flex-direction: column;
                gap: 10px;

                & input{
                    padding: 10px 15px;
                    border-radius: 100px;
                    border: none;
                }

                & button{
                    padding: 10px;
                    border-radius: 100px;
                    border: none;
                    background-color: white;
                    color: #3e3e42;
                    cursor: pointer;
                }
            }
        }

        & .contact {

            & .info{
                display: flex;
                flex-direction: column;
                gap: 10px;

                & a{
                    text-decoration: none;
                    color: #3e3e42;
                }

                & .social{
                    display: flex;
                    gap: 10px;
                    padding-top: 50px;

                    & a{
                        text-decoration: none;
                        color: #3e3e42;
                    }
                }
            }
        }

        & .legal {
            
            & ul{
                display: flex;
                flex-direction: column;
                gap: 10px;

                & a{
                    text-decoration: underline;
                    color: #3e3e42;
                }
            }
        }

        & .menu {

            & ul{
                display: flex;
                flex-direction: column;
                gap: 10px;

                & a{
                    text-decoration: underline;
                    color: #3e3e42;
                }
            }
        }
    }

    & hr{
        width: 98%;
        border: none;
        border-top: 1px solid rgb(114, 114, 114);
    }

    & .logo {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20rem;
    }
}
.productPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2%;
    position: relative;
    border-radius: 100px;

    & .background {
        width: 100%;
        min-height: 96vh;
        border-radius: 46px;
        background-color: #4b4843;
        padding: 200px 3% 100px 3%;
        display: flex;
        gap: 50px;

        & .bear-react-carousel__root{
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            & .bear-react-carousel__nav-button{
                background-color: #4B4843;
            }
        }

        & .sliderImg {
            width: 100%;
            min-height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 40px;
        }

        & .product {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 50px;
            color: whitesmoke;

            & .productInfo {

                & .price {
                    color: #C9BBA7;
                }
            }

            & .quantity {
                display: flex;
                flex-direction: column;
                gap: 10px;

                & h3 {
                    font-weight: 100;
                    color: #C9BBA7;
                }

                & .quantityInput {
                    border: 1px solid whitesmoke;
                    padding: 5px 10px;
                    border-radius: 100px;
                    width: 150px;
                    display: flex;
                    align-items: center;

                    & .btn{
                        cursor: pointer;
                        background: none;
                        outline: transparent;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: whitesmoke;

                        &:disabled {
                            color: rgba(245, 245, 245, 0.274);
                        }
                    }

                    & input {
                        border: none;
                        background: none;
                        outline: transparent;
                        color: whitesmoke;
                        width: 100%;
                        text-align: center;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                }
            }

            & .addToCart {
                width: 100%;
                padding: 10px 20px;
                border: 1px solid #C9BBA7;
                border-radius: 100px;
                font-size: 1.3rem;
                color: whitesmoke;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.2s linear;
                background: none;

                &:hover {
                    background-color: whitesmoke;
                    color: #4B4843;
                }
            }

            & .shopInfo {
                display: flex;
                flex-direction: column;
                gap: 20px;

                & hr {
                    width: 98%;
                    border: none;
                    border-top: 1px solid rgb(114, 114, 114);
                }

                & .collapse {
                    width: 100%;
                    padding-right: 40px;
                    display: flex;
                    position: relative;
                    height: 1lh;
                    overflow: hidden;
                    transition: all 0.2s linear;

                    & h4 {
                        color: #C9BBA7;
                    }

                    & label {
                        width: 100%;
                        height: 30px;
                        position: absolute;
                        transition: all 0.2s linear;
                        cursor: pointer;

                        &::after {
                            content: '';
                            position: absolute;
                            right: 15px;
                            top: 9px;
                            width: 0;
                            height: 0;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-top: 10px solid whitesmoke;
                            transition: all 0.2s linear;
                        }
                    }

                    & input {
                        display: none;
                    }

                    & #collapseBtn1:checked~.collapseLabel1 {
                        transform: rotateX(180deg);
                    }

                    & #collapseBtn2:checked~.collapseLabel2 {
                        transform: rotateX(180deg);
                    }

                    & #collapseBtn3:checked~.collapseLabel3 {
                        transform: rotateX(180deg);
                    }
                }

                & .collapse1:has(input:checked) {
                    height: auto;
                    height: calc-size(auto);
                    transition: all 0.2s linear;
                }

                & .collapse2:has(input:checked) {
                    height: auto;
                    height: calc-size(auto);
                    transition: all 0.2s linear;
                }

                & .collapse3:has(input:checked) {
                    height: auto;
                    height: calc-size(auto);
                    transition: all 0.2s linear;
                }
            }
        }
    }
}
.main {

    & .featured {
        display: flex;
        flex-direction: column;
        gap: 100px;

        & .mission {
            width: 75%;
            display: flex;
            flex-direction: column;
            gap: 50px;
            position: sticky;
            top: 100px;
            padding: 4%;
            padding-top: 100px;

            & h2 {
                font-weight: 500;
            }

            & p {
                font-size: 3rem;
                font-weight: 100;
            }

            & a {
                text-decoration: none;
                border: 1px solid #3e3e42;
                border-radius: 100px;
                padding: 15px 20px;
                min-width: 200px;
                width: fit-content;
                color: #3e3e42;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.2s linear;

                &:hover {
                    background-color: #3e3e42;
                    color: white;
                }
            }
        }

        & .productsContent {
            width: 100%;
            position: relative;
            z-index: 1;
            padding-top: 600px;

            & .background{
                background: #CCCBC4;
                background: linear-gradient(0deg, #CCCBC4 0%, #ffffff00 100%);
                height: 600px;
                width: 100%;
                position: absolute;
                top: 0;
            }

            & h2 {
                text-align: center;
                font-size: 3rem;
                font-weight: 100;
                background: #CCCBC4;
                padding-top: 50px;
            }

            & .products {
                position: relative;
                width: 100%;
                height: fit-content;
                height: 2400px;
                background: #CCCBC4;

                & .product {
                    position: absolute;
                    height: auto;
                    width: 31.25%;
                    padding: 1.2%;
                    background-color: white;
                    display: flex;
                    flex-direction: column;
                    gap: 50px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 40px;
                    text-decoration: none;
                    color: #3e3e42;

                    &:focus {
                        color: #3e3e42;
                    }

                    & img {
                        width: 100%;
                        aspect-ratio: 2/1;
                        height: fit-content;
                        object-fit: cover;
                        object-position: 50% 50%;
                        overflow-clip-margin: content-box;
                        overflow: clip;
                        border-radius: 30px;
                    }

                    & .productContent {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        justify-content: center;
                        align-items: center;

                        & a {
                            text-decoration: none;
                            border: 1px solid #3e3e42;
                            border-radius: 100px;
                            padding: 10px 15px;
                            min-width: 200px;
                            width: fit-content;
                            color: #3e3e42;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: all 0.2s linear;

                            &:hover {
                                background-color: #3e3e42;
                                color: white;
                            }
                        }
                    }
                }

                & .product:nth-child(1) {
                    left: 61%;
                    top: max(100px, 6%);
                }

                & .product:nth-child(2) {
                    left: 18%;
                    top: 500px;
                }

                & .product:nth-child(3) {
                    left: 55%;
                    top: 1000px;
                }

                & .product:nth-child(4) {
                    left: 9%;
                    top: 1600px;
                }
            }
        }
    }
}
.main {

    & .benefit-socials {
        padding: 100px 0 100px 0;

        & .benefit {
            display: flex;
            flex-direction: row;
            gap: 20px;
            justify-content: space-between;
            align-items: center;
            padding: 100px 0px;
            color: #3e3e42;
            padding: 0 2%;
            position: sticky;
            top: 100px;

            & img{
                width: 50%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            & .textContent {
                display: flex;
                flex-direction: column;
                gap: 30px;

                & h2 {
                    font-weight: 100;
                }

                & p {
                    font-size: 3rem;
                    max-width: 1500px;
                }

                & a {
                    text-decoration: none;
                    border: 1px solid #3e3e42;
                    border-radius: 100px;
                    padding: 10px 20px;
                    min-width: 200px;
                    width: fit-content;
                    color: #3e3e42;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s linear;

                    &:hover {
                        background-color: #3e3e42;
                        color: white;
                    }
                }
            }
        }

        & .socials {
            display: flex;
            flex-direction: column;
            gap: 200px;
            justify-content: center;
            align-items: center;
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%);
            padding-top: 400px;
            height: 100vh;
            position: relative;
            z-index: 1;

            & .instagram {
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: center;
                align-items: center;
                color: #3e3e42;

                & h2 {
                    font-size: 3rem;
                    font-weight: 300;
                }

                & a {
                    text-decoration: none;
                    border: 1px solid #3e3e42;
                    border-radius: 100px;
                    padding: 10px 20px;
                    min-width: 200px;
                    width: fit-content;
                    color: #3e3e42;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s linear;

                    &:hover {
                        background-color: #3e3e42;
                        color: white;
                    }
                }
            }

            & .imgSlider {
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 0px 0px 100px 0px;

                & .sliderImg {
                    width: 100%;
                    height: 100%;
                    max-height: 500px;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
}